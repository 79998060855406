/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import Divider from 'apollo-react/components/Divider';
import Box from '@mui/material/Box';
import Typography from 'apollo-react/components/Typography';
import Button from 'apollo-react/components/Button';
import Step from 'apollo-react/components/Step';
import StepLabel from 'apollo-react/components/StepLabel';
import Stepper from 'apollo-react/components/Stepper';
import { useSelector, useDispatch } from 'react-redux';
import { saveAddGlobalLibrary, editGlobalLibrary } from 'Redux/Service/GlobalLibraryService';
import { showBanner } from 'Redux/Slice/BannerSlice';
import CustomModal from '../Modal';
import { useNavigate, useLocation } from 'react-router-dom';
import { convertExcelToJson, fileByteToFileObject } from 'Utils';
import TextField from 'apollo-react/components/TextField';
import Cookies from 'js-cookie';
import md5 from 'md5';
import { getVariableRuleSets } from 'Redux/Service/AddGlobalLibraryService';
import { unwrapResult } from '@reduxjs/toolkit';

export default function SideBar({
  onSubmitRef,
  isValidate,
  validateRuleSet,
  handleStepperback,
  getPayload,
  path
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isAddForm, setIsAddForm] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const globalLibraryData = useSelector(
    (state) => state?.ViewGlobalLibrary?.globalLibraries?.data?.libraries
  );
  const { rowData } = useSelector((state) => state.EditGlobalData);
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [reasonForChange, setReasonForChange] = React.useState(false);
  const dispatch = useDispatch();
  const [justification, setJustification] = React.useState('');
  const [isReason, setIsReason] = useState(true);
  const [isSubmitBtnDisabled, setIsSubmitBtn] = useState(false);
  let steps = [isAddForm ? 'Add Global Library' : 'Edit Global Library', 'Preview'];
  const GLOBAL_LIBRARY_PATH = '/data-standard-library/global-library';
  const stepName = activeStep === steps.length - 1 ? 'Finish' : 'Next';

  useEffect(() => {
    if (
      !location.pathname
        .substring(location.pathname.lastIndexOf('/') + 1, location.pathname.length)
        .startsWith('add')
    ) {
      setIsAddForm(false);
    }
  });

  const versionIncrement = () => {
    let version = 0;
    if (rowData) {
      const filteredGlobalData = globalLibraryData.filter(
        (data) => data.libraryID === rowData.libraryID
      );
      filteredGlobalData.forEach((f) => {
        if (version < f.version) {
          version = f.version;
        }
      });
    }
    return version + 1;
  };

  const getVariableRuleSetsOnEdit = async () => {
    const variableRuleSetsResp = await dispatch(getVariableRuleSets(rowData)).then(unwrapResult);
    if (variableRuleSetsResp?.data?.success) {
      return variableRuleSetsResp.data.variableRulesets;
    } else {
      dispatch(showBanner({ variant: 'error', message: variableRuleSetsResp?.data?.message }));
    }
  };

  const generatePayload = () => {
    const data = getPayload();
    let version = versionIncrement();
    const variableRulesets = [];

    data.type === 'Ruleset' &&
      data.variableRulesets.forEach((item) => {
        variableRulesets.push({
          variableName: item.variableName,
          label: item.label,
          expressions: item.expressions?.map((e) => e.expression?.trim()),
          status: item.status
        });
      });

    const variableRuleSetsObj = {
      variableRulesets:
        !variableRulesets.length && !isAddForm && data.type === 'Ruleset'
          ? variableRuleSetData
          : variableRulesets
      // domainColumns: null,
    };

    const NonRuleSetObj = {
      libraryType: data.type,
      libraryName: data.name,
      description: data.description,
      version: version,
      userid: Cookies.get('user.id'),
      auditDate: new Date().toISOString(),
      comments: data.comments,
      fileName: data.type === 'Ruleset' ? '' : data.fileName,
      category: data.category,
      libraryID: rowData ? rowData.libraryID : '',
      auditType: rowData?.libraryID ? 'UPDATE' : 'INSERT',
      fileBytes: data.fileByte === null ? null : data.fileByte.split('base64,')[1]
    };

    if (data.type === 'Ruleset') {
      return {
        libraries: NonRuleSetObj,
        variableRulesets: variableRuleSetsObj.variableRulesets
      };
    } else {
      return {
        libraries: NonRuleSetObj
      };
    }
  };

  const compareBothVariableRuleSets = (oldExpValidation, newExpValidation) => {
    let flagCheck = true;
    for (var i = 0; i < oldExpValidation.length; i++) {
      if (oldExpValidation[i].variableRuleSetID !== newExpValidation[i].variableRuleSetID)
        flagCheck = false;
    }
    return (
      oldExpValidation.length === newExpValidation.length &&
      flagCheck &&
      oldExpValidation.every((item1) =>
        newExpValidation.some(
          (item2) =>
            item1.variableName === item2.variableName &&
            item1.label === item2.label &&
            item1.status === item2.status &&
            item1.expressions?.every((exp) => item2.expressions?.includes(exp)) &&
            item1.variableRuleSetID === item2.variableRuleSetID
        )
      )
    );
  };

  const computeRulesetHash = async () => {
    let isFormValueChanged = false;
    if (rowData) {
      const variableRuleSetData = await getVariableRuleSetsOnEdit();
      const data = await getPayload();
      const oldExpValidation = variableRuleSetData;
      const newExpValidation =
        data.variableRulesets.length === 0 && !isAddForm
          ? variableRuleSetData
          : data.variableRulesets;

      const flag = await compareBothVariableRuleSets(oldExpValidation, newExpValidation);
      console.log(flag, 'flag');

      const oldUserData = {
        libraryType: rowData.libraryType,
        libraryName: rowData.libraryName,
        description: rowData.description,
        fileData: '',
        comments: rowData.comments,
        category: rowData.category
      };

      const newUserData = {
        libraryType: data.type,
        libraryName: data.name,
        description: data.description,
        fileData: '',
        comments: data.comments,
        category: data.category
      };

      isFormValueChanged =
        flag && md5(JSON.stringify(oldUserData)) === md5(JSON.stringify(newUserData));
    }
    return isFormValueChanged;
  };

  const computeHash = async () => {
    let isFormValueChanged = false;
    if (rowData) {
      const data = getPayload();

      const fileByte = await editGlobalLibrary(rowData);
      const oldFileObj = await fileByteToFileObject(
        'data:application/octet-stream;base64,' + fileByte.data.fileBytes,
        rowData.fileName
      );
      const oldFileJson = await convertExcelToJson(oldFileObj);

      const oldUserData = {
        libraryType: rowData.libraryType,
        libraryName: rowData.libraryName,
        description: rowData.description,
        fileData: oldFileJson,
        comments: rowData.comments,
        category: rowData.category
      };

      const newFileObj = await fileByteToFileObject(data.fileByte, data.fileName);
      const newFileJson = await convertExcelToJson(newFileObj);
      const newUserData = {
        libraryType: data.type,
        libraryName: data.name,
        description: data.description,
        fileData: newFileJson,
        comments: data.comments,
        category: data.category
      };

      isFormValueChanged = md5(JSON.stringify(oldUserData)) === md5(JSON.stringify(newUserData));
    }
    return isFormValueChanged;
  };

  const handleNext = () => {
    if (path !== '/data-standard-library/add-study-library') {
      if (activeStep === steps.length - 1) {
        if (!isAddForm) {
          (rowData.libraryType !== 'Ruleset' ? computeHash() : computeRulesetHash()).then(
            (isFormChanged) => {
              if (!isFormChanged) {
                setReasonForChange(true);
              } else {
                handleSubmitRef();
                dispatch(showBanner({ variant: 'info', message: 'No data to persist' }));
              }
            }
          );
        } else {
          const payload = generatePayload();
          setIsSubmitBtn(true);
          saveAddGlobalLibrary(payload)
            .then((res) => {
              return res.json();
            })
            .catch(() => {
              setIsSubmitBtn(false);
            })
            .then((response) => {
              setIsSubmitBtn(false);
              if (response.success) {
                dispatch(
                  showBanner({ variant: 'success', message: response.message, propagate: true })
                );
                navigate(GLOBAL_LIBRARY_PATH);
              } else {
                dispatch(showBanner({ variant: 'error', message: response.message }));
              }
            });
        }
      } else {
        handleSubmitRef();
      }
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleSubmitRef = () => {
    validateRuleSet();
    onSubmitRef();
    /**
     * Checks the active tabs
     * update the local state with flag
     */
    if (activeStep === steps.length - 1) {
      setIsSubmitBtn(true);
    }

    const isFormValid = isValidate();
    if (isFormValid) {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    handleStepperback();
    setActiveStep(activeStep - 1);
    setIsSubmitBtn(false);
  };

  const handleBackToEdit = () => {
    handleStepperback();
    setActiveStep(activeStep - 2);
    setIsSubmitBtn(false);
  };

  const openModal = async () => {
    setConfirmAlert(true);
  };

  const handleReset = () => {
    handleStepperback();
    setActiveStep(0);
    setConfirmAlert(false);
    navigate(GLOBAL_LIBRARY_PATH);
  };

  const reasonForChangeReset = () => {
    if (justification) {
      setIsReason(true);
      const payload = generatePayload();
      payload.libraries.reasonForChange = justification;
      saveAddGlobalLibrary(payload)
        .then((res) => {
          setReasonForChange(false);
          return res.json();
        })
        .then((response) => {
          if (response.success) {
            dispatch(
              showBanner({ variant: 'success', message: response.message, propagate: true })
            );
            navigate(GLOBAL_LIBRARY_PATH);
          } else {
            dispatch(showBanner({ variant: 'error', message: response.message }));
          }
        });
      handleSubmitRef();
    } else {
      setIsReason(false);
    }
  };
  const reasonForChangeClose = () => {
    setReasonForChange(false);
  };

  const reasonForChangeValue = (e) => {
    setJustification(e.target.value);
  };

  const handleClose = () => {
    setConfirmAlert(false);
  };

  const handleCancel = () => {
    navigate(GLOBAL_LIBRARY_PATH);
  };
  return (
    <>
      <Box>
        <Box pt={3} pb={3} px={3}>
          <Typography variant="h3" data-testid="workflow">
            Workflow
          </Typography>
        </Box>
        <Divider type="dark" />
        <Box style={{ padding: '30px 20px' }}>
          <div style={{ maxWidth: 400 }}>
            <Stepper box activeStep={activeStep}>
              {steps.map((label) => (
                <Step key={label} style={{ padding: '18px 16px' }}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div>
              {activeStep === steps.length ? (
                <Box mt={2} display="flex" justifyContent="space-between">
                  <Button variant="secondary" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Box display="flex" justifyContent="flex-end" flex={0.6}>
                    <Button
                      variant="secondary"
                      onClick={handleBackToEdit}
                      style={{ marginRight: '16px' }}>
                      Back
                    </Button>
                    <Button variant="secondary" onClick={handleReset} data-testid="reset">
                      Reset
                    </Button>
                  </Box>
                </Box>
              ) : (
                <Box mt={2} display="flex" justifyContent="space-between">
                  <Button
                    flex={0.4}
                    variant="text"
                    size="small"
                    label="Back"
                    onClick={openModal}
                    style={{ paddingLeft: 0 }}
                    data-testid="cancel">
                    Cancel
                  </Button>
                  <Box display="flex" justifyContent="flex-end" flex={0.6}>
                    {activeStep === steps.length - 1 && (
                      <Button
                        variant="secondary"
                        size="small"
                        onClick={handleBack}
                        style={{ marginRight: '16px' }}
                        data-testid="back">
                        Back
                      </Button>
                    )}
                    <Button
                      variant="primary"
                      size="small"
                      onClick={handleNext}
                      data-testid="next"
                      disabled={isSubmitBtnDisabled}>
                      {stepName}
                    </Button>
                  </Box>
                </Box>
              )}
            </div>
          </div>
        </Box>
      </Box>
      <CustomModal
        display={confirmAlert}
        title={'Leave Page?'}
        buttonPrimaryLabel={'Ok'}
        message={
          'Changes could not be saved if you leave this page, Would you still like to leave?'
        }
        handlePrimaryAction={() => handleReset()}
        buttonSecondardyLabel={'Cancel'}
        handleClose={() => handleClose()}
      />
      {!isAddForm && (
        <CustomModal
          display={reasonForChange}
          title={'Reason For Change'}
          buttonPrimaryLabel={'Ok'}
          message={
            <>
              <Typography>Please provide a reason for the update(s) made.</Typography>
              <div style={{ maxWidth: 400 }}>
                <TextField
                  id="reasonForChange"
                  label=""
                  name="reasonForChange"
                  value={justification}
                  placeholder="Enter Text"
                  onChange={reasonForChangeValue}
                  fullWidth
                />
              </div>
              {!isReason && <div style={{ color: 'red' }}>Required Field</div>}
            </>
          }
          handlePrimaryAction={() => reasonForChangeReset()}
          buttonSecondardyLabel={'Cancel'}
          handleClose={() => reasonForChangeClose()}
        />
      )}
    </>
  );
}
